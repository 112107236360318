
import { defineComponent } from "vue-demi";
import { Chart, registerables } from "chart.js";
import { ADMIN_STAFF_TABLE_OPTIONS } from "@/store/common/table";
import { useAPI } from "@/use";
import { dynamicsObject } from "@/interfaces";
import CommonTable from '@/components/common/table/index.vue';
import AmountOrder from './diagrams/AmountOrder.vue';
import QuantityOrder from './diagrams/QuantityOrder.vue';

export default defineComponent({
  name: 'AdminSettingMain',
  data() {
    return {
      staff: ADMIN_STAFF_TABLE_OPTIONS(),
      diagram: null as Array<dynamicsObject> | null,
    }
  },
  created() {
    this.getDiagramMethod();
    Chart.register(...registerables);
    Chart.defaults.font.family = 'ALSSchlangesans';
    Chart.defaults.borderColor = '#EBF0F3';
  },
  methods: {
    async getDiagramMethod() {
      const result = await useAPI().order.getDiagramMethod();
      this.diagram = result.data;
    },
  },
  components: {
    CommonTable,
    AmountOrder,
    QuantityOrder
  }
})
