
import { defineComponent } from "vue-demi";
import SettingMain from "@/components/admin/setting/index.vue";

export default defineComponent({
  name: "SettingView",
  components: {
    SettingMain,
  },
});
