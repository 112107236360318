import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a9310b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting" }
const _hoisted_2 = { class: "setting--staff" }
const _hoisted_3 = { class: "setting--diagrams flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_table = _resolveComponent("common-table")!
  const _component_amount_order = _resolveComponent("amount-order")!
  const _component_quantity_order = _resolveComponent("quantity-order")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_common_table, { options: _ctx.staff }, null, 8, ["options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_amount_order, {
        class: "common--block common--block-small",
        diagram: _ctx.diagram
      }, null, 8, ["diagram"]),
      _createVNode(_component_quantity_order, {
        class: "common--block common--block-small",
        diagram: _ctx.diagram
      }, null, 8, ["diagram"])
    ])
  ]))
}