
import { defineComponent } from "vue-demi";
import { LineChart } from "vue-chart-3";
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "QuantityOrderDiagram",
  props: {
    diagram: {
      type: Object,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
            grid: {
              color: "#fff",
            },
            ticks: {
              padding: 5,
            },
          },
          y: {
            display: true,
            grid: {
              borderWidth: 0,
            },
            ticks: {
              padding: 10,
            },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              pointStyle: "rectRounded",
              usePointStyle: true,
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      const data = {} as dynamicsObject;
      data.labels = this.diagram?.map((d: { date: number }) => d.date);
      data.datasets = [
        {
          data: this.diagram?.map((e: { quantity: number }) => e.quantity),
          label: "Заказы",
          backgroundColor: ["#FBD249"],
          borderColor: ["#FBD249"],
          borderWidth: 2,
        },
      ];
      return data;
    },
    total() {
      return this.diagram?.reduce(
        (acc: number, d: { quantity: number }) => acc + d.quantity,
        0
      );
    },
  },
  components: {
    LineChart,
  },
});
